:root {
  --button-color-start: #00FF87;
  --button-color-end: #60EFFF;

  --button-disabled-color-start: rgba(178, 255, 81, 0.60);
  --button-disabled-color-end: rgba(78, 232, 140, 0.60);
}

.button {
  border: none;
  padding: 0 20px;
  cursor: pointer;
  background-size: 300% 100%;
  background-image: linear-gradient(90deg, var(--button-color-start), var(--button-color-end),var(--button-color-start), var(--button-color-end));
  animation: animateBg 8s linear infinite;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: #111;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 2px;
  font-family: 'Bai Jamjuree', sans-serif;
}

.button:disabled {
  cursor: pointer;
  background-size: 300% 100%;
  background-image: linear-gradient(90deg, var(--button-disabled-color-start), var(--button-disabled-color-end), var(--button-disabled-color-start), var(--button-disabled-color-end));
  animation: animateBg 8s linear infinite;
}

@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}