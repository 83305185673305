/* Carousel.css */
.slide-fade-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 700ms ease-in-out, transform 700ms ease-in-out;
}

.slide-fade-exit {
  opacity: 1;
}

.slide-fade-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 700ms ease-in-out, transform 700ms ease-in-out;
}