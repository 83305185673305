.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.loading-spinner-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #70F06B;
  margin: 0 5px;
  animation: loading-spinner 1s ease-in-out infinite;
}

.loading-spinner-circle:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-spinner-circle:nth-child(3) {
  animation-delay: 0.4s;
}


@keyframes loading-spinner {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
