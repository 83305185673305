.slick-dots li {
  margin: 0 ; 
}

.slick-dots li button:before {
  font-size: 8px;    
  color: rgba(112, 240, 107, 0.30);     
}

.slick-dots li.slick-active button:before {
  color: #70F06B;        
}
